import { Title } from "@solidjs/meta";
import CogedimAvantagesPage from "~/components/CogedimAvantages/CogedimAvantagesPage";
import { MetaTags } from "~/components/shared/MetaTags";

export default function CogedimAccessMonthlySearchEngine() {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Les solutions les plus performantes pour investir dans l’immobilier.",
    },
  };

  return (
    <>
      <Title>Cogedim Avantages redonne le pouvoir aux investisseurs</Title>
      <MetaTags tags={[metaDesc]} />

      <CogedimAvantagesPage />
    </>
  );
}
