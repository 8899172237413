import { useSearchParams } from "@solidjs/router";
import {
  createMemo,
  createRenderEffect,
  createResource,
  Show,
  Suspense,
} from "solid-js";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { getDataAtPath } from "~/lib/fetcherInternal";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import type { Landing } from "~/types/drupal_jsonapi";
import { urlRs } from "~/utils/url";
import GtmVars from "../shared/Trackers/GtmVars";
import { clientOnly } from "@solidjs/start";
import { DebugDumper } from "../Forms/Utils/DebugDumper";
import LandingPrograms from "../Landing/Components/LandingPrograms";
import LotDrawer from "../Program/Components/LotDrawer";
import { useLotActive } from "~/contexts/LotActiveContext";
import { utmStoreGet } from "~/lib/utm_store";
import { getFormEventNameFromCrmObject } from "../Program/Components/ProgramFormInPage";
import FirstScreen from "~/components/CogedimAvantages/Components/FirstScreen";
import SeoTextIntro from "~/components/CogedimAvantages/Components/SeoTextIntro";
import SeoTextSolutions from "~/components/CogedimAvantages/Components/SeoTextSolutions";

import "./CogedimAvantagesPage.css";
import SeoFaqs from "~/components/CogedimAvantages/Components/SeoFaqs";
import Breadcrumb from "~/components/shared/Breadcrumb";
import Reviews from "~/components/CogedimAvantages/Components/Reviews";

const FormInPage = clientOnly(() => import("../../components/Forms/InPage"));

const debug = false;

export default function CogedimAvantagesPage() {
  const [lotProvider] = useLotActive();
  const settings = useDrupalSettingsContext();
  const [, setSearchParams] = useSearchParams();
  const [landing] = createResource(
    () => settings.landing_avantages_nid,
    (id) => getDataAtPath<Landing>(`solid/landing/${id}`),
  );

  const canShowLanding = () => {
    return landing() && landing()?.field_form;
  };

  // Re-init page params
  setSearchParams({ page: undefined });

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    setTemplate("cogedim-avantages");
  });

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      text: "Cogedim pour vous",
    };
    items.push(root);

    const breadCrumbLinks: BreadcrumbItem[] = [
      {
        href: urlRs("journeys", "/cogedim-pour-vous/pourquoi-choisir-cogedim/"),
        text: "Pourquoi choisir Cogedim",
      },
      {
        href: urlRs(
          "journeys",
          "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/",
        ),
        text: "Le nouveau neuf",
      },
    ];

    breadCrumbLinks.forEach((item) => items.push(item));

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: "Cogedim Avantages",
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": breadcrumbItems().at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems().at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems().at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems().at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems().at(4)?.text ?? "Non défini",
      template: "cogedim-avantages",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <Show when={debug}>
        <DebugDumper
          name="Store"
          data={{
            landing: landing(),
          }}
        />
      </Show>

      <article class="cogedim-avantages-page">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer
              wrapper={lotProvider.wrapper!}
              lot={lotProvider.lot!}
              isNotFromProgram
            />
          </Suspense>
        </Show>

        <FirstScreen />

        <SeoTextIntro />

        <SeoTextSolutions />

        <Suspense>
          <Show when={landing() && canShowLanding()}>
            <section id="residences-eligibles">
              <LandingPrograms landing={landing()!} enableRegulations />
            </section>
          </Show>
        </Suspense>

        <Suspense>
          <Show when={landing() && canShowLanding()}>
            <section id="accompagnement">
              <FormInPageWrapper landing={landing()!} />
            </section>
          </Show>
        </Suspense>

        <SeoFaqs />

        <Reviews />
      </article>

      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}

function FormInPageWrapper(props: { landing: Landing }) {
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
      node_nid: props.landing.drupal_internal__nid,
    };
  };

  return (
    <>
      <div class="node-landing">
        <section class="form-in-page-wrapper">
          <div class="form-in-page form-in-page-white" data-test="form-in-page">
            <Show when={props.landing.field_form?.field_title}>
              <h2>{props.landing.field_form!.field_title}</h2>
            </Show>
            <div data-ga-zone="form">
              <Show when={props.landing.field_form?.field_catchline}>
                <p class="catchline" data-test="catchline">
                  {props.landing.field_form!.field_catchline}
                </p>
              </Show>
              <FormInPage
                landing={props.landing}
                formConfig={props.landing.field_form}
                hiddenFields={{ form_id: "in-page", ...hiddenFields() }}
                formNameForEvent={getFormEventNameFromCrmObject(
                  props.landing.field_form!.field_crm_object,
                )}
                forceDestination="investir"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
