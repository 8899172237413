import Accordion from "@corvu/accordion";

import "./SeoFaqs.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function SeoFaqs() {
  return (
    <>
      <section class="content-part seo-faqs" data-test="faqs">
        <h2>
          <strong>Réponses aux principales interrogations</strong>
          sur l’investissement locatif et les dispositifs fiscaux.
        </h2>
        <div class="faqs-list" data-test="faqs">
          <Accordion collapseBehavior="hide">
            <Accordion.Item as="div" class="node-qa" data-test="qa">
              <Accordion.Trigger
                as="h3"
                class="question-h3"
                data-test="question"
              >
                Pourquoi investir dans l’immobilier locatif neuf en 2025&nbsp;?
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </Accordion.Trigger>
              <Accordion.Content data-test="answer">
                <div class="answer-content">
                  <p>
                    L’investissement locatif neuf est une valeur refuge qui
                    permet de constituer un patrimoine, de percevoir des revenus
                    complémentaires et de bénéficier d’une fiscalité optimisée.
                    Avec des dispositifs comme la location meublée (LMNP), le
                    Logement Locatif Intermédiaire (LLI) ou la nue-propriété,
                    vous profitez d’un rendement sécurisé et d’un bien attractif
                    pour les locataires. Investir avec Cogedim, c’est aussi
                    accéder à des programmes immobiliers de qualité, pensés pour
                    une rentabilité optimale.
                  </p>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item as="div" class="node-qa" data-test="qa">
              <Accordion.Trigger
                as="h3"
                class="question-h3"
                data-test="question"
              >
                Quel dispositif d’investissement locatif choisir selon mon
                profil&nbsp;?
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </Accordion.Trigger>
              <Accordion.Content data-test="answer">
                <div class="answer-content">
                  <p>
                    Le choix de votre solution d’investissement dépend de vos
                    objectifs&nbsp;:
                  </p>
                  <ul>
                    <li>
                      <strong>Maximiser vos revenus locatifs&nbsp;?</strong>{" "}
                      Optez pour la location meublée (LMNP), qui permet une
                      fiscalité avantageuse grâce à l’amortissement du bien.
                    </li>
                    <li>
                      <strong>
                        Sécuriser votre investissement avec des loyers
                        garantis&nbsp;?
                      </strong>{" "}
                      La location meublée gérée offre un bail commercial
                      sécurisé avec des revenus assurés.
                    </li>
                    <li>
                      <strong>
                        Réduire le prix d’achat et optimiser la fiscalité&nbsp;?
                      </strong>{" "}
                      Le Logement Locatif Intermédiaire (LLI) vous permet de
                      bénéficier d’une TVA réduite à 10&nbsp;% et d’un crédit
                      d’impôt sur la taxe foncière.
                    </li>
                    <li>
                      <strong>
                        Investir sans gestion et avec une forte décote&nbsp;?
                      </strong>{" "}
                      La nue-propriété vous permet d’acheter un bien avec une
                      réduction allant jusqu’à 39&nbsp;% et sans aucune
                      contrainte de gestion.
                    </li>
                  </ul>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item as="div" class="node-qa" data-test="qa">
              <Accordion.Trigger
                as="h3"
                class="question-h3"
                data-test="question"
              >
                Quels sont les avantages du statut LMNP pour un
                investisseur&nbsp;?
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </Accordion.Trigger>
              <Accordion.Content data-test="answer">
                <div class="answer-content">
                  <p>
                    Le Loueur en Meublé Non Professionnel (LMNP) est une
                    solution idéale pour optimiser la rentabilité de votre
                    investissement locatif&nbsp;:
                  </p>
                  <ul>
                    <li>
                      Un cadre fiscal avantageux&nbsp;: grâce à l’amortissement
                      du bien et à la déduction des charges, vous réduisez,
                      voire annulez, l’imposition sur vos revenus locatifs.
                    </li>
                    <li>
                      Un loyer plus élevé qu’en location nue, avec une forte
                      demande pour les logements meublés en zones tendues.
                    </li>
                    <li>
                      Une flexibilité dans la gestion&nbsp;: vous pouvez louer
                      directement ou déléguer la gestion à un professionnel.
                    </li>
                    <li>
                      Possibilité d’investir en résidence gérée (étudiante,
                      senior, jeunes actifs) pour percevoir des loyers garantis.
                    </li>
                  </ul>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item as="div" class="node-qa" data-test="qa">
              <Accordion.Trigger
                as="h3"
                class="question-h3"
                data-test="question"
              >
                Comment fonctionne le Logement Locatif Intermédiaire (LLI) et
                quels sont ses avantages&nbsp;?
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </Accordion.Trigger>
              <Accordion.Content data-test="answer">
                <div class="answer-content">
                  <p>
                    Le Logement Locatif Intermédiaire (LLI) permet d’acheter un
                    bien neuf avec une TVA réduite à 10&nbsp;%, ce qui diminue
                    immédiatement le coût d’acquisition. Il donne aussi droit à
                    un crédit d’impôt sur la taxe foncière pouvant aller jusqu’à
                    20 ans.
                  </p>
                  <p>
                    En contrepartie, le bien est loué à des ménages aux revenus
                    intermédiaires, garantissant une forte demande locative.
                    Vous pouvez investir en LLI classique ou en LLI meublé,
                    combinant ainsi fiscalité avantageuse et rentabilité accrue.
                  </p>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item as="div" class="node-qa" data-test="qa">
              <Accordion.Trigger
                as="h3"
                class="question-h3"
                data-test="question"
              >
                Pourquoi choisir Cogedim pour mon investissement
                immobilier&nbsp;?
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </Accordion.Trigger>
              <Accordion.Content data-test="answer">
                <div class="answer-content">
                  <p>
                    Cogedim est un acteur majeur de l’immobilier neuf, offrant
                    des solutions adaptées à chaque investisseur&nbsp;:
                  </p>
                  <ul>
                    <li>
                      Une large gamme de solutions locatives (meublé, géré, LLI,
                      nue-propriété…).
                    </li>
                    <li>
                      Des programmes immobiliers de qualité situés dans des
                      secteurs à forte demande.
                    </li>
                    <li>
                      Un accompagnement sur-mesure pour le financement, la
                      fiscalité et la gestion locative.
                    </li>
                    <li>
                      Des avantages exclusifs&nbsp;: frais de notaire offerts,
                      mobilier inclus, gestion locative prise en charge…
                    </li>
                  </ul>
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
}
