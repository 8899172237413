import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { createSignal, Show } from "solid-js";

import "./Reviews.css";

import IconChevronLeft from "~/img/icons/chevron_left.svg";
import IconChevronRight from "~/img/icons/chevron_right.svg";

export default function Reviews() {
  const [viewportSizeProvider] = useViewportSizeContext();

  const SlidesCount = 6;
  const [sliderMobilePosition, setSliderMobilePosition] = createSignal(0);
  const [sliderDesktopPosition, setSliderDesktopPosition] = createSignal(0);

  function goPreviousMobile() {
    if (sliderMobilePosition() > 0) {
      setSliderMobilePosition(sliderMobilePosition() - 1);
    }
  }

  function goNextMobile() {
    if (sliderMobilePosition() < SlidesCount - 1) {
      setSliderMobilePosition(sliderMobilePosition() + 1);
    }
  }

  function goPreviousDesktop() {
    if (sliderDesktopPosition() > 0) {
      setSliderDesktopPosition(sliderDesktopPosition() - 1);
    }
  }

  function goNextDesktop() {
    if (sliderDesktopPosition() < Math.ceil(SlidesCount / 2) - 1) {
      setSliderDesktopPosition(sliderDesktopPosition() + 1);
    }
  }

  return (
    <>
      <section class="reviews-cogedim">
        <div class="content-part reviews" data-test="customer-reviews">
          <h2>Ils nous ont fait confiance pour leur investissement</h2>

          <div
            class={`reviews-slider slide-mobile-${sliderMobilePosition()} slide-desktop-${sliderDesktopPosition()}`}
          >
            <div class="buttons">
              <Show
                when={viewportSizeProvider.viewPortIsLessThan768}
                fallback={
                  <>
                    <button
                      type="button"
                      class="prev-desktop"
                      data-test="btn-prev"
                      onClick={() => goPreviousDesktop()}
                    >
                      <i aria-hidden="true" class="cog-icon">
                        <IconChevronLeft />
                      </i>
                    </button>
                    <button
                      type="button"
                      class="next-desktop"
                      data-test="btn-next"
                      onClick={() => goNextDesktop()}
                    >
                      <i aria-hidden="true" class="cog-icon">
                        <IconChevronRight />
                      </i>
                    </button>
                  </>
                }
              >
                <button
                  type="button"
                  class="prev-mobile"
                  onClick={() => goPreviousMobile()}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconChevronLeft />
                  </i>
                </button>
                <button
                  type="button"
                  class="next-mobile"
                  onClick={() => goNextMobile()}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconChevronRight />
                  </i>
                </button>
              </Show>
            </div>

            <div class="review-cards" data-test="cards">
              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Très satisfait&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  De l’achat à la livraison bonne prise en charge client.
                  Disponibilité, rigueur, accueil. J’ai même recommandé Cogedim
                  à un ami pour un investissement. Très satisfait.
                </p>
                <p class="submitted">
                  Publié le 16 mai 2024
                  <br />
                  par Olivier D.
                </p>
              </div>

              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Très bon accompagnement&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  Très bon accompagnement sur un projet d’investissement
                  immobilier de très belle qualité.
                </p>
                <p class="submitted">
                  Publié le 02 octobre 2023
                  <br />
                  par Béatrice A.
                </p>
              </div>

              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Bien accompagnés&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  1er investissement locatif dans lequel nous sommes bien
                  accompagnés.
                </p>
                <p class="submitted">
                  Publié le 07 juin 2023
                  <br />
                  par Emmanuelle D.
                </p>
              </div>

              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Premier investissement&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  Premier investissement locatif qui sera réussi sans aucun
                  doute grâce à Cogedim&nbsp;!!
                </p>
                <p class="submitted">
                  Publié le 26 avril 2023
                  <br />
                  par Constance G.
                </p>
              </div>

              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Professionnalisme&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  Laetitia Martin nous a accompagné avec une grande écoute et
                  disponibilité sur notre 1er projet d'investissement et pour
                  lequel nous avions une attente forte de conseil et
                  d'accompagnement. Merci pour son professionnalisme.
                </p>
                <p class="submitted">
                  Publié le 03 février 2023
                  <br />
                  par Marie-Anne R.
                </p>
              </div>

              <div class="review-card" data-test="card-review">
                <h3>«&nbsp;Suivi et conseils tops&nbsp;»</h3>
                <img
                  src="/images/front-page/immo-rating-5.png"
                  alt=""
                  width="105"
                  height="16"
                />
                <p>
                  Le suivi et les conseils sont tops. Rassurant pour notre
                  investissement à distance.
                </p>
                <p class="submitted">
                  Publié le 02 févier 2023
                  <br />
                  par Cécile V.
                </p>
              </div>
            </div>
          </div>

          <div class="button">
            <a href="#accompagnement" class="btn" data-test="anchor">
              Contacter un expert
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
